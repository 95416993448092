export const INTERNAL_HEADERS = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': (
    document.querySelector('meta[name=csrf-token]') as HTMLMetaElement
  ).content,
  'Content-Type': 'application/json',
};

export const API_V2_INTERNAL_HEADERS = {
  Accept: 'application/json',
  Authorization: `Bearer ${window.VHX?.config?.token || ''}`,
  'Content-Type': 'application/json',
};

export const parseResponse = async (response: Response) => {
  // if the response is not ok (i.e. an error), let's parse the body to see if there are additional details included, such as a message, and return that to the caller
  if (!response?.ok) {
    const errorDetails = {
      status: response.status,
      statusText: response.statusText,
    };
    try {
      const parsedResponse = await response.json();

      return Promise.reject({
        ...errorDetails,
        message: parsedResponse?.message || null,
        type: parsedResponse?.type || null, // an optional error type from the server
      });
    } catch (error) {
      // handle error if response.json() call fails
      return Promise.reject(errorDetails);
    }
  }

  // else return the parsed response body as-is
  return response.json();
};
